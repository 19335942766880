import Resource from '@guestyci/agni';

const QUERY_IDS = {
  GET_LISTING: 'GET_LISTING',
  GET_LISTINGS: 'GET_LISTINGS',
  GET_LISTING_FIELDS: 'GET_LISTING_FIELDS',
  GET_LISTING_PAYMENT_PROVIDER: 'GET_LISTING_PAYMENT_PROVIDER',
  GET_LISTING_MARKERS: 'GET_LISTING_MARKERS',

  CREATE_QUOTE: 'CREATE_QUOTE',
  GET_QUOTE: 'GET_QUOTE',
  CREATE_GROUP_QUOTE: 'CREATE_GROUP_QUOTE',
  GET_GROUP_QUOTE: 'GET_GROUP_QUOTE',

  SETUP_INTENTS: 'SETUP_INTENTS',

  ADD_COUPON: 'ADD_COUPON',
  GET_WEBSITE_SETTINGS: 'GET_WEBSITE_SETTINGS',
  GET_LISTINGS_CALENDAR: 'GET_LISTINGS_CALENDAR',
  GET_FEATURED_LISTINGS: 'GET_FEATURED_LISTINGS',
  GET_CITIES: 'GET_CITIES',

  GET_USER_LOCATION: 'GET_USER_LOCATION',

  GET_CURRENCY_RATE: 'GET_CURRENCY_RATE',

  GET_UPSELLS: 'GET_UPSELLS',
};

const { api, env } = Resource.create({
  domain: '/pm-websites-backend',
  env: process.env.REACT_APP_ENV_CLUSTER,
  development: 'localhost',
});

export { api, env, QUERY_IDS };
